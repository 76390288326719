require("normalize.css/normalize.css");
require("./styles/index.scss");

import $ from "jquery";
import LazyLoad from "vanilla-lazyload";
import Swiper from "swiper";
import { Pagination, Autoplay, EffectCreative } from "swiper/modules";
import "swiper/swiper-bundle.css";

Swiper.use([Pagination, Autoplay, EffectCreative]);
var lazyLoadInstance = new LazyLoad();

$(window).on("load", function () {
  setTimeout(function () {
    $("body").addClass("loaded");
    if (swiper) swiper.autoplay.start();
  }, 1000);
});

setTimeout(function () {
  $("body").addClass("loaded");
  if (swiper) swiper.autoplay.start();
}, 30000);

const swiper = new Swiper(".intro-container", {
  loop: true,
  pagination: {
    el: ".intro-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 5000,
    enabled: false,
  },
  grabCursor: true,
  effect: "creative",
  creativeEffect: {
    prev: {
      opacity: 0,
      translate: ["-120%", 0, -500],
    },
    next: {
      opacity: 0,
      translate: ["120%", 0, -500],
    },
  },
  on: {
    slideChange: function () {
      var introSection = document.querySelector(".intro");

      if (swiper && swiper.realIndex === 1) {
        introSection.classList.add("dark");
        introSection.classList.remove("light");
      } else {
        introSection.classList.add("light");
        introSection.classList.remove("dark");
      }
    },
  },
});

function calculateVh(initial) {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");
  if (initial == true) document.documentElement.style.setProperty("--ivh", vh + "px");
}

calculateVh(true);
//window.addEventListener("resize", calculateVh);
window.addEventListener("orientationchange", calculateVh(true));

$(document).on("click", 'a[href^="#"]:not([href="#"]), a[href^="/#"]:not([href="/#"])', function (event) {
  var newPath = $(this).attr("href");
  if (newPath.startsWith("/#") && window.location.pathname === "/") {
    event.preventDefault();
    var anchor = newPath.substring(2);
    var scrollTo = $("#" + anchor).offset().top;
    $("html, body").animate(
      {
        scrollTop: scrollTo,
      },
      500
    );
  } else if (newPath.startsWith("/#")) {
    window.location.href = window.location.origin + newPath;
  }
});

$(document).on("click", ".header-nav-handler", function (event) {
  event.preventDefault();
  $("body").toggleClass("nav-visible");
});

$(document).on("click", ".header-nav a, .nav-fade", function (event) {
  $("body").removeClass("nav-visible");
});

// Fetch counters
$.ajax({
  url: "https://tonapi.io/v2/rates?tokens=ton&currencies=usd",
  method: "GET",
  success: function (rateData) {
    var poolDataPromise = $.ajax({
      url: "https://tonapi.io/v2/staking/pool/EQCkWxfyhAkim3g2DjKQQg8T5P4g-Q1-K_jErGcDJZ4i-vqR",
      method: "GET",
    });

    var historyDataPromise = $.ajax({
      url: "https://tonapi.io/v2/staking/pool/EQCkWxfyhAkim3g2DjKQQg8T5P4g-Q1-K_jErGcDJZ4i-vqR/history",
      method: "GET",
    });

    Promise.all([poolDataPromise, historyDataPromise])
      .then(function (responses) {
        var poolData = responses[0];
        var historyData = responses[1];
        var protocolFee = 0.86
        // Convert TVL from TON to USD
        var tonToUsdRate = rateData.rates.TON.prices.USD;
        var tvlInUsd = (poolData.pool.total_amount * tonToUsdRate) / 1e9; // Dividing by 1e9 for correct conversion

        // Display TVL in the proper format
        var formattedTvlInUsd = tvlInUsd > 1e6 ? (tvlInUsd / 1e6).toFixed(1) + " M" : tvlInUsd.toFixed(2);

        // Display stakers count with commas
        var formattedStakers = poolData.pool.current_nominators.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        var currentTime = Math.floor(Date.now() / 1000);
        // Define the number of milliseconds in 365 days
        var intervalSeconds = 365 * 24 * 60 * 60;
        // Filter the history data to include only items within the last 365 days and where apy is neither 0 nor NaN
        var intervalItems = historyData.apy.filter(function(item) {
            return (currentTime - item.time) <= intervalSeconds &&
                   item.apy !== 0 &&
                   !isNaN(item.apy);
        });

        // Extract the APY values from the filtered items
        var intervalApys = intervalItems.map(function(item) {
            return item.apy;
        });

        // Calculate the average APY based on the filtered items
        var averageApy = intervalApys.reduce(function(sum, apy) {
            return sum + apy;
        }, 0) / intervalApys.length * protocolFee;


        // Display the values
        $("#counter-stakers").text(formattedStakers).removeClass("shimmer");
        $("#counter-apy")
          .text(averageApy.toFixed(2) + "%")
          .removeClass("shimmer");
        $("#counter-tvl")
          .text("$" + formattedTvlInUsd)
          .removeClass("shimmer");
      })
      .catch(function (error) {
        console.log("Error fetching data:", error);
      });
  },
  error: function (error) {
    console.log("Error fetching rates:", error);
  },
});

$(document).ready(function () {
  var lastScrollTop = 0;
  var delta = 50;

  if ($(".header").length) {
    $(".header").clone().addClass("sticky hidden").appendTo("body");
  }

  function adjustHeader() {
    var st = $(this).scrollTop();
    if (Math.abs(lastScrollTop - st) <= delta) return;

    if (st > lastScrollTop || lastScrollTop == 0) {
      if ($(window).scrollTop() > window.innerHeight) {
        $(".header.sticky").removeClass("hidden");
      }
    } else {
      $(".header.sticky").addClass("hidden");
    }
    lastScrollTop = st <= 0 ? 0 : st;
  }

  $(window).scroll(adjustHeader);
});

$(".faq-list-item-question").on("click", function (event) {
  this.parentElement.classList.toggle("enrolled");
});
